import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../slices/Auth/authSlice";
import { getCompanyById, myCompanySelector, updateCompanyById } from "../../slices/Entities/companiesSlice";
import Navbar from "../Navbar/navbar";
import {
  AccountBalance,
  Badge,
  CreditCard,
  DriveFileRenameOutline,
  Home,
  LocationCity,
  Map,
  Percent,
  Person,
  Public,
  QrCode2,
} from "@mui/icons-material";
import { Company } from "../../services/companyService";
import { notifyUpdateCompany } from "../../utils/notify";
import ErrorNotification from "../../components/ErrorNotification/errorNotification";
import { isUndefined } from "lodash";
import { allUsersSelectors, getUsersList } from "../../slices/Entities/usersSlice";
import { User } from "../../services/authService";
import { validationSchemaCompany } from "../../utils/ValidationSchemas";

const MyCompany = () => {
  const dispatch = useDispatch<any>();
  const user = useSelector(userSelector);
  const myCompany = useSelector(myCompanySelector);
  const users = useSelector(allUsersSelectors);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [company, setCompany] = useState({
    name: "",
    slug: "",
    owner: {} as User,
    vatCode: "",
    registrationCode: "",
    address: "",
    city: "",
    county: "",
    country: "",
    bank: "",
    iban: "",
  });

  const [owner, setOwner] = useState<any>("");

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getUsersList());
      await dispatch(getCompanyById(user.company.id));
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (myCompany) {
      setCompany({
        registrationCode: "",
        address: "",
        city: "",
        county: "",
        country: "",
        bank: "",
        iban: "",
        ...myCompany,
        owner: myCompany.owner.id,
      } as Company);
      setOwner(myCompany.owner.id);
    }
  }, [myCompany]);

  const resetErrorMessage = () => {
    setErrorMessage(undefined);
  };

  const handleUpdateCompany = (formValue: any) => {
    dispatch(
      updateCompanyById({
        id: myCompany.id,
        company: { ...formValue, owner: owner } as Company,
      })
    ).then((result: any) => {
      if (!result.error) {
        notifyUpdateCompany();
      } else {
        setErrorMessage(result.payload.response.data);
      }
    });
  };
  const handleChangeOwner = (value: any) => {
    setOwner(value.target.value);
  };

  return (
    <div className="dashboard-container">
      <Navbar />
      <div>
        <div className="update-container center-info">
          <div className="info-container margins-info-container">
            <Formik enableReinitialize initialValues={company} onSubmit={handleUpdateCompany} validationSchema={validationSchemaCompany}>
              <div>
                <Form className="modal-form">
                  <div>
                    <ErrorMessage name="name" render={(msg) => <span className="error-msg">{msg}</span>} />
                    <div className="personal-data-company">
                      <div className="personal-data-title">Name</div>
                      <Field name="name">
                        {({ field, meta: { touched, error } }: any) => (
                          <TextField
                            name="name"
                            className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                            type="text"
                            {...field}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Badge />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <ErrorMessage name="slug" render={(msg) => <span className="error-msg">{msg}</span>} />
                    <div className="personal-data-company">
                      <div className="personal-data-title">Slug</div>
                      <Field name="slug">
                        {({ field, meta: { touched, error } }: any) => (
                          <TextField
                            name="slug"
                            className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                            type="text"
                            {...field}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <DriveFileRenameOutline />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <ErrorMessage name="owner" render={(msg) => <span className="error-msg">{msg}</span>} />
                    <div className="personal-data-company">
                      <div className="personal-data-title">Owner</div>
                      <Field name="owner">
                        {({ field, meta: { touched, error } }: any) => (
                          <TextField
                            id="select-textfield"
                            className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                            select
                            {...field}
                            value={owner}
                            disabled={true}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person />
                                </InputAdornment>
                              ),
                            }}
                            onChange={handleChangeOwner}
                          >
                            {users.map((user: any) => (
                              <MenuItem value={user.id} key={user.id}>
                                {user.firstName} {user.lastName}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </Field>
                    </div>
                  </div>
                  <ErrorMessage name="registrationCode" render={(msg) => <span className="error-msg">{msg}</span>} />
                  <div className="personal-data-company">
                    <div className="personal-data-title">Registration Code</div>
                    <Field name="registrationCode">
                      {({ field, meta: { touched, error } }: any) => (
                        <TextField
                          name="registrationCode"
                          className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                          type="text"
                          {...field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <QrCode2 />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <ErrorMessage name="vatCode" render={(msg) => <span className="error-msg">{msg}</span>} />
                  <div className="personal-data-company">
                    <div className="personal-data-title">VAT Code</div>
                    <Field name="vatCode">
                      {({ field, meta: { touched, error } }: any) => (
                        <TextField
                          name="vatCode"
                          className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                          type="text"
                          {...field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Percent />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <ErrorMessage name="address" render={(msg) => <span className="error-msg">{msg}</span>} />
                  <div className="personal-data-company">
                    <div className="personal-data-title">Address</div>
                    <Field name="address">
                      {({ field, meta: { touched, error } }: any) => (
                        <TextField
                          name="address"
                          className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                          type="text"
                          {...field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Home />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <ErrorMessage name="city" render={(msg) => <span className="error-msg">{msg}</span>} />
                  <div className="personal-data-company">
                    <div className="personal-data-title">City</div>
                    <Field name="city">
                      {({ field, meta: { touched, error } }: any) => (
                        <TextField
                          name="city"
                          className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                          type="text"
                          {...field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocationCity />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <ErrorMessage name="county" render={(msg) => <span className="error-msg">{msg}</span>} />
                  <div className="personal-data-company">
                    <div className="personal-data-title">County</div>
                    <Field name="county">
                      {({ field, meta: { touched, error } }: any) => (
                        <TextField
                          name="county"
                          className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                          type="text"
                          {...field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Map />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <ErrorMessage name="country" render={(msg) => <span className="error-msg">{msg}</span>} />
                  <div className="personal-data-company">
                    <div className="personal-data-title">Country</div>
                    <Field name="country">
                      {({ field, meta: { touched, error } }: any) => (
                        <TextField
                          name="country"
                          className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                          type="text"
                          {...field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Public />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <ErrorMessage name="bank" render={(msg) => <span className="error-msg">{msg}</span>} />
                  <div className="personal-data-company">
                    <div className="personal-data-title">Bank</div>
                    <Field name="bank">
                      {({ field, meta: { touched, error } }: any) => (
                        <TextField
                          name="bank"
                          className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                          type="text"
                          {...field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountBalance />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <ErrorMessage name="iban" render={(msg) => <span className="error-msg">{msg}</span>} />
                  <div className="personal-data-company">
                    <div className="personal-data-title">IBAN</div>
                    <Field name="iban">
                      {({ field, meta: { touched, error } }: any) => (
                        <TextField
                          name="iban"
                          className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                          type="text"
                          {...field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CreditCard />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="personal-data-company">
                    <div className="personal-data-title"></div>
                    <button type="submit" className="input-with-icon-textfield update-button">
                      Update
                    </button>
                  </div>
                </Form>
              </div>
            </Formik>
          </div>
        </div>
      </div>
      {!isUndefined(errorMessage) && <ErrorNotification message={errorMessage} onClose={resetErrorMessage} />}
    </div>
  );
};

export default MyCompany;
